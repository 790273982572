define("pixelportal-web/components/page-milestones/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wTFQWStj",
    "block": "{\"symbols\":[\"milestone\"],\"statements\":[[9,\"style\",true],[10],[1,1,0,0,\"\\n.milestone-table td {\\n    padding: 5px 7px;\\n    color: #434343;\\n    font-family: Arial, sans-serif;\\n}\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[9,\"table\",true],[12,\"class\",\"milestone-table\",null],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,3,\"BlockHead\"],[]],[[31,0,0,[27,[26,2,\"CallHead\"],[]],[[31,0,0,[27,[26,2,\"CallHead\"],[]],[[27,[26,1,\"Expression\"],[]]],null]],null]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"    \"],[9,\"tr\",true],[10],[1,1,0,0,\"\\n      \"],[9,\"td\",true],[10],[1,0,0,0,[31,211,13,[27,[26,0,\"CallHead\"],[]],[[27,[24,1],[\"dateFormatted\"]],\"dddd D MMMM\"],null]],[11],[1,1,0,0,\"\\n      \"],[9,\"td\",true],[10],[1,0,0,0,[27,[24,1],[\"data\",\"name\"]]],[11],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[1]}]]],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"moment-format\",\"milestones\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "pixelportal-web/components/page-milestones/template.hbs"
    }
  });

  _exports.default = _default;
});