define("pixelportal-web/components/page-login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kGMHeOJn",
    "block": "{\"symbols\":[],\"statements\":[[9,\"h1\",true],[13,\"class\",[32,[[27,[26,0,\"AppendSingleId\"],[]],\"__title\"]],null],[10],[1,1,0,0,\"\\n  \"],[1,0,0,0,[27,[26,1,\"AppendSingleId\"],[]]],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[1,0,0,0,[27,[26,2,\"AppendSingleId\"],[]]],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"styleNamespace\",\"title\",\"login-form\"]}",
    "meta": {
      "moduleName": "pixelportal-web/components/page-login/template.hbs"
    }
  });

  _exports.default = _default;
});