define("pixelportal-web/components/page-projects/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    projectsProvider: Ember.inject.service('projects'),
    title: 'Projecten',
    projectsActiveTitle: 'Lopende projecten',
    projectsDoneTitle: 'Afgeronde projecten',
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments); // Retrieve projects from database on component init.


      this.get('projectsProvider').getProjects().then(function (payload) {
        _this.setProjectsActive(payload.data.getProjects);

        _this.setProjectsDone(payload.data.getProjects);
      }).catch(function () {
        // TODO: Use proper error handling.
        _this.set('error', 'Er konden geen projecten worden geladen.');
      });
    },
    setProjectsActive: function setProjectsActive(projects) {
      this.set('projectsActive', this.findByStatus(projects, 'active'));
    },
    setProjectsDone: function setProjectsDone(projects) {
      this.set('projectsDone', this.findByStatus(projects, 'done'));
    },
    // In an array of projects, find all items with supplied status.
    // Returns a new array of projects.
    findByStatus: function findByStatus(projects, status) {
      var result = [];

      for (var i = 0; i < projects.length; i++) {
        if (projects[i].status == status) {
          result.push(projects[i]);
        }
      }

      return result;
    }
  });

  _exports.default = _default;
});