define("pixelportal-web/components/projects-list-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HPejqDoi",
    "block": "{\"symbols\":[],\"statements\":[[9,\"h3\",true],[13,\"class\",[32,[[27,[26,0,\"AppendSingleId\"],[]],\"__title\"]],null],[10],[1,0,0,0,[27,[26,1,\"Expression\"],[\"title\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"span\",true],[13,\"class\",[32,[[27,[26,0,\"AppendSingleId\"],[]],\"__description\"]],null],[10],[1,0,0,0,[27,[26,1,\"Expression\"],[\"description\"]]],[11],[1,1,0,0,\"\\n\"],[9,\"span\",true],[13,\"class\",[32,[[27,[26,0,\"AppendSingleId\"],[]],\"__status\"]],null],[10],[1,1,0,0,\"Status: \"],[1,0,0,0,[27,[26,2,\"AppendSingleId\"],[]]],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"styleNamespace\",\"project\",\"projectStatus\"]}",
    "meta": {
      "moduleName": "pixelportal-web/components/projects-list-card/template.hbs"
    }
  });

  _exports.default = _default;
});