define("pixelportal-web/components/logo-pixelportal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'figure',
    altText: 'Pixelportal'
  });

  _exports.default = _default;
});