define("pixelportal-web/components/login-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t5NKmTz0",
    "block": "{\"symbols\":[],\"statements\":[[9,\"form\",false],[14,\"class\",[32,[[27,[26,0,\"AppendSingleId\"],[]],\"__form\"]],null],[3,0,0,[27,[26,1,\"ModifierHead\"],[]],[[27,[24,0],[]],\"login\"],[[\"on\"],[\"submit\"]]],[10],[1,1,0,0,\"\\n  \"],[9,\"label\",true],[13,\"class\",[32,[[27,[26,0,\"AppendSingleId\"],[]],\"__label\"]],null],[10],[1,1,0,0,\"E-mailadres\"],[11],[1,1,0,0,\"\\n  \"],[1,0,0,0,[31,143,5,[27,[26,4,\"CallHead\"],[]],null,[[\"value\",\"type\",\"class\"],[[27,[26,3,\"Expression\"],[]],\"email\",[31,193,6,[27,[26,2,\"CallHead\"],[]],[[27,[26,0,\"Expression\"],[]],\"__input\"],null]]]]],[1,1,0,0,\"\\n  \"],[9,\"button\",true],[13,\"class\",[32,[[27,[26,0,\"AppendSingleId\"],[]],\"__button\"]],null],[12,\"type\",\"submit\",null],[10],[1,1,0,0,\"\\n    Verder\\n  \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[1,0,0,0,[27,[26,5,\"AppendSingleId\"],[]]],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"styleNamespace\",\"action\",\"concat\",\"email\",\"input\",\"errorMessage\"]}",
    "meta": {
      "moduleName": "pixelportal-web/components/login-form/template.hbs"
    }
  });

  _exports.default = _default;
});