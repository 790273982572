define("pixelportal-web/mixins/protected-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable-next-line
  var _default = Ember.Mixin.create({
    auth: Ember.inject.service(),
    redirect: function redirect() {
      if (!this.get('auth.isAuthenticated')) {
        this.transitionTo('/inloggen');
      }
    }
  });

  _exports.default = _default;
});