define("pixelportal-web/components/the-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eU9VdYjt",
    "block": "{\"symbols\":[],\"statements\":[[1,0,0,0,[31,2,16,[27,[26,2,\"CallHead\"],[]],null,[[\"class\"],[[31,26,6,[27,[26,1,\"CallHead\"],[]],[[27,[26,0,\"Expression\"],[]],\"the-header__logo\"],null]]]]],[1,1,0,0,\"\\n\"],[1,0,0,0,[31,72,21,[27,[26,3,\"CallHead\"],[]],null,[[\"class\"],[[31,101,6,[27,[26,1,\"CallHead\"],[]],[[27,[26,0,\"Expression\"],[]],\"the-header__navigation\"],null]]]]],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"styleNamespace\",\"concat\",\"logo-pixelportal\",\"the-header-navigation\"]}",
    "meta": {
      "moduleName": "pixelportal-web/components/the-header/template.hbs"
    }
  });

  _exports.default = _default;
});