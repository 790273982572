define("pixelportal-web/components/logo-pixelportal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zm2rwt6r",
    "block": "{\"symbols\":[],\"statements\":[[9,\"img\",true],[12,\"src\",\"./assets/img/logo-pixelportal.svg\",null],[13,\"alt\",[27,[26,0,\"AppendSingleId\"],[]],null],[13,\"class\",[32,[[27,[26,1,\"AppendSingleId\"],[]],\"__image\"]],null],[10],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"altText\",\"styleNamespace\"]}",
    "meta": {
      "moduleName": "pixelportal-web/components/logo-pixelportal/template.hbs"
    }
  });

  _exports.default = _default;
});