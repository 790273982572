define("pixelportal-web/components/projects-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ygUe4r0b",
    "block": "{\"symbols\":[\"project\"],\"statements\":[[9,\"h2\",true],[13,\"class\",[32,[[27,[26,1,\"AppendSingleId\"],[]],\"__title\"]],null],[10],[1,0,0,0,[27,[26,2,\"AppendSingleId\"],[]]],[11],[1,1,0,0,\"\\n\"],[9,\"ul\",true],[13,\"class\",[32,[[27,[26,1,\"AppendSingleId\"],[]],\"__projects\"]],null],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,5,\"BlockHead\"],[]],[[31,0,0,[27,[26,4,\"CallHead\"],[]],[[31,0,0,[27,[26,4,\"CallHead\"],[]],[[27,[26,3,\"Expression\"],[]]],null]],null]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"    \"],[1,0,0,0,[31,135,18,[27,[26,0,\"CallHead\"],[]],null,[[\"project\"],[[27,[24,1],[]]]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[1]}]]],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"projects-list-card\",\"styleNamespace\",\"title\",\"projects\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "pixelportal-web/components/projects-list/template.hbs"
    }
  });

  _exports.default = _default;
});