define("pixelportal-web/components/page-projects/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iAmz6BGm",
    "block": "{\"symbols\":[],\"statements\":[[9,\"h1\",true],[13,\"class\",[32,[[27,[26,5,\"AppendSingleId\"],[]],\"__title\"]],null],[10],[1,1,0,0,\"\\n  \"],[1,0,0,0,[27,[26,6,\"AppendSingleId\"],[]]],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[5,[27,[26,7,\"BlockHead\"],[]],[[27,[26,3,\"Expression\"],[]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[1,0,0,0,[31,85,13,[27,[26,2,\"CallHead\"],[]],null,[[\"title\",\"projects\"],[[27,[26,4,\"Expression\"],[]],[27,[26,3,\"Expression\"],[]]]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"],[5,[27,[26,7,\"BlockHead\"],[]],[[27,[26,0,\"Expression\"],[]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[1,0,0,0,[31,196,13,[27,[26,2,\"CallHead\"],[]],null,[[\"title\",\"projects\"],[[27,[26,1,\"Expression\"],[]],[27,[26,0,\"Expression\"],[]]]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"],[9,\"span\",true],[13,\"class\",[32,[[27,[26,5,\"AppendSingleId\"],[]],\"__error\"]],null],[10],[1,0,0,0,[27,[26,8,\"AppendSingleId\"],[]]],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"projectsDone\",\"projectsDoneTitle\",\"projects-list\",\"projectsActive\",\"projectsActiveTitle\",\"styleNamespace\",\"title\",\"if\",\"error\"]}",
    "meta": {
      "moduleName": "pixelportal-web/components/page-projects/template.hbs"
    }
  });

  _exports.default = _default;
});