define("pixelportal-web/components/the-header-navigation/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var pages = [{
    title: 'Planning & werkwijze',
    routerLink: 'page-planning'
  }, {
    title: 'Team',
    routerLink: 'page-team'
  }, {
    title: 'Handleidingen',
    routerLink: 'page-manuals'
  }, {
    title: 'Projecten',
    routerLink: 'page-projects'
  }, {
    title: 'Documenten',
    routerLink: 'page-documents'
  }];

  var _default = Ember.Component.extend({
    auth: Ember.inject.service(),
    tagName: 'nav',
    pages: pages
  });

  _exports.default = _default;
});