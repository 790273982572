define("pixelportal-web/components/projects-list-card/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    init: function init() {
      this._super.apply(this, arguments);

      this.setProjectStatusText();
    },
    setProjectStatusText: function setProjectStatusText() {
      if (this.get('project').status == 'active') this.set('projectStatus', 'actief');
      if (this.get('project').status == 'done') this.set('projectStatus', 'voltooid');
    }
  });

  _exports.default = _default;
});