define("pixelportal-web/components/the-header-navigation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x5aLRwRS",
    "block": "{\"symbols\":[\"page\"],\"statements\":[[9,\"ul\",true],[13,\"class\",[32,[[27,[26,0,\"AppendSingleId\"],[]],\"__list\"]],null],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,8,\"BlockHead\"],[]],[[27,[26,7,\"Expression\"],[\"isAuthenticated\"]]],null,[[\"default\"],[{\"statements\":[[5,[27,[26,5,\"BlockHead\"],[]],[[31,0,0,[27,[26,4,\"CallHead\"],[]],[[31,0,0,[27,[26,4,\"CallHead\"],[]],[[27,[26,3,\"Expression\"],[]]],null]],null]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"      \"],[9,\"li\",true],[13,\"class\",[32,[[27,[26,0,\"AppendSingleId\"],[]],\"__item\"]],null],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,2,\"BlockHead\"],[]],null,[[\"class\",\"activeClass\",\"title\",\"route\"],[[31,205,6,[27,[26,1,\"CallHead\"],[]],[[27,[26,0,\"Expression\"],[]],\"__link\",\" \"],null],[31,264,6,[27,[26,1,\"CallHead\"],[]],[[27,[26,0,\"Expression\"],[]],\"__link--active\"],null],[27,[24,1],[\"title\"]],[27,[24,1],[\"routerLink\"]]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"          \"],[1,0,0,0,[27,[24,1],[\"title\"]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"      \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[1]}]]],[1,1,0,0,\"    \"],[9,\"li\",true],[13,\"class\",[32,[[27,[26,0,\"AppendSingleId\"],[]],\"__item\"]],null],[10],[1,1,0,0,\"\\n      \"],[1,0,0,0,[31,464,13,[27,[26,6,\"CallHead\"],[]],null,[[\"class\"],[[31,485,6,[27,[26,1,\"CallHead\"],[]],[[27,[26,0,\"Expression\"],[]],\"__link\"],null]]]]],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"styleNamespace\",\"concat\",\"link-to\",\"pages\",\"-track-array\",\"each\",\"logout-button\",\"auth\",\"if\"]}",
    "meta": {
      "moduleName": "pixelportal-web/components/the-header-navigation/template.hbs"
    }
  });

  _exports.default = _default;
});