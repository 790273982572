define("pixelportal-web/components/logout-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LtGlidBQ",
    "block": "{\"symbols\":[],\"statements\":[[9,\"button\",true],[13,\"class\",[32,[[27,[26,0,\"AppendSingleId\"],[]],\"__button\"]],null],[13,\"onclick\",[31,57,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[]],\"logout\"],null],null],[10],[1,1,0,0,\"\\n  \"],[1,0,0,0,[27,[26,2,\"AppendSingleId\"],[]]],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"styleNamespace\",\"action\",\"title\"]}",
    "meta": {
      "moduleName": "pixelportal-web/components/logout-button/template.hbs"
    }
  });

  _exports.default = _default;
});